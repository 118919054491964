<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <FormItem>
        <span>订单号：</span>
        <i-input v-model="queryFrom.order_code" placeholder="请输入" class="iviewIptWidth250 marginRight60"></i-input>
      </FormItem>
      <FormItem>
        <span>客户名称：</span>
        <Select class="iviewIptWidth250 marginRight60" filterable v-model="supplierIndex" clearable>
          <Option v-for="(item, index) in clientNameList" :value="index" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span class="label">下单时间：</span>
        <DatePicker :editable='false'  class="iviewIptWidth250" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime($event, 1)" clearable></DatePicker><span class="choseTimeZhi">至</span><DatePicker :editable='false'  v-model="queryFrom.order_time_end" :options="options" class="iviewIptWidth250" format="yyyy-MM-dd" clearable placeholder="请选择" @on-change="changeTime($event, 2)"></DatePicker>
      </FormItem>
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" totalText="条记录"> </Table>
  </div>
</template>

<script>
import Table from '@/components/table'
import { mapState } from 'vuex'
export default {
  name:'acceptanceDetailsConsign',
  components: {
    Table,
  },
  computed: {
    ...mapState(['supplierNameList', 'clientNameList']),
  },
  data() {
    return {
      supplierIndex: -1,
      options: {},
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '订单号',
          key: 'order_code',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {

                  },
                  on: {},
                },
                param.row.order_code
              ),
            ])
          },
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
        },
        {
          title: '订单金额',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', param.row.order_price ? '¥' + param.row.order_price : '0')])
          },
        },
        {
          title: '订单时间',
          key: 'orderTime',
          align: 'center',
        },
        {
          title: '发货数量',
          key: 'delivery_amount',
          align: 'center',
        },
        {
          title: '医院入库数量',
          key: 'customer_storage_in_amount',
          align: 'center',
        },
        {
          title: '医院挂账数量',
          key: 'customer_charge_amount',
          align: 'center',
        },
      ],
      listData: [],
      total: 0,
      queryFrom: {
        page: 1,
        rows: 10,
        order_code: '',
        customer_id: '',
        customer_type: '',
        order_time_begin: '',
        order_time_end: '',
      },
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.order_time_begin = e
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.order_time_begin) - 86400000
          },
        }
      } else {
        this.queryFrom.order_time_end = e
      }
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      this.isLoad = true
      if (this.supplierIndex >= 0) {
        this.queryFrom.customer_id = this.clientNameList[this.supplierIndex].customer_id
        this.queryFrom.customer_type = this.clientNameList[this.supplierIndex].customer_type
      } else {
        this.queryFrom.customer_id = ''
        this.queryFrom.customer_type = ''
      }
      this.$http.get(this.$apiConsign.saleOrderReceiveDetail, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].orderTime = res.data[i].order_time ? this.$moment(res.data[i].order_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
    },
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
  },
}
</script>

<style scoped lang="less">
.marginRight60 {
  margin-right: 60px;
}
</style>
